


import { Component, Vue } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import InfiniteLoading, {StateChanger} from 'vue-infinite-loading';
import CompaniesMap from '@/_modules/promo-hall/components/companies-map.vue';
import broadcastsService from '@/_services/broadcasts.service';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TPromoPageFavorite} from '@/_modules/promo/api/promo-page.api';
import CompaniesListItem from '@/_modules/promo-hall/components/companies-list-item/companies-list-item.vue';

@Component({
  components: {
    CompaniesMap,
    InfiniteLoading,
    CompaniesListItem
  },
})
export default class CompaniesList extends Vue {

  @Getter('promoPageStore/isLoading') readonly isLoading: boolean;
  @Getter('promoPageStore/isViewTypeList') readonly isViewTypeList: boolean;
  @Getter('promoPageStore/isViewTypeMap') readonly isViewTypeMap: boolean;
  @Getter('promoPageStore/isListTypeAll') readonly isListTypeAll: boolean;
  @Getter('promoPageStore/isListTypeMy') readonly isListTypeMy: boolean;
  @Getter('promoPageStore/getListAll') readonly getListAll: TPromoPage[];
  @Getter('promoPageStore/isListAllMore') readonly isListAllMore: boolean; // TODO: better naming
  @Getter('promoPageStore/getListMy') readonly getListMy: TPromoPage[]; // TODO: better naming
  @Getter('promoPageStore/isListMyMore') readonly isListMyMore: boolean; // TODO: better naming
  @Getter('promoPageStore/isFilters') readonly isFilters: boolean; // TODO: better naming
  @Getter('promoPageStore/isPavilions') readonly isPavilions: boolean; // TODO: better naming
  @Getter('promoPageStore/getListAllFilteredByPavilion') readonly getListAllFilteredByPavilion: TPromoPage[]; // TODO: remove if unused

  @Action('promoStore/promoPageListAll') promoPageListAll: (payload: { event_id: number }) => TPromoPage[];
  @Action('promoStore/promoPageListMy') promoPageListMy: (payload: { event_id: number }) => TPromoPage[];
  @Action('promoPageStore/setListAll') setListAll: () => Promise<TPromoPage[]>;
  @Action('promoPageStore/setListMy') setListMy: () => Promise<TPromoPage[]>;
  @Action('promoPageStore/addFavorite') addFavorite: (payload: TPromoPageFavorite) => Promise<void>;
  @Action('promoPageStore/removeFavorite') removeFavorite: (payload: TPromoPageFavorite) => Promise<void>;

  public customOffset: number = 0;
  public findBroadCastInterval: number = null;
  public promoPagesInterval: number = null;
  public activeBroadcasts: any[] = [];

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEmpty(): boolean {
    let empty = false;
    if (this.isListTypeAll) {
      empty = this.getListAll.length === 0;
    } else if (this.isListTypeMy) {
      empty = this.getListMy.length === 0;
    }
    return empty;
  };

  public get defineListSource(): TPromoPage[] {
    return this.isListTypeAll ? this.getListAll : this.getListMy;
  };

  public get defineInfiniteHandler(): boolean {
    return this.isListTypeAll ? this.isListAllMore : this.isListMyMore;
  }

  public get isCompaniesListVertical(): boolean {
    return !this.isViewTypeMap && !!this.$route.params.external_id;
  }

  public beforeDestroy(): void {
    window.clearInterval(this.findBroadCastInterval);
    window.clearInterval(this.promoPagesInterval);
  }

  public mounted(): void {
    if (this.$route.params.external_id) {
      if (this.defineListSource.length === 0) {
        this.onInfiniteHandler();
      }
      this.scrollOnTopByExternalId();
    }

    this.findBroadCastInterval = window.setInterval(() => {
      for (let i = 0; i < this.activeBroadcasts.length; i++) {
        const companyListItem: HTMLElement = document.getElementById(`companyBadge_${this.activeBroadcasts[i].details.promopage.external_id}`);

        if (companyListItem) {
          companyListItem.classList.remove('active-broadcast');
        }
      }
      this.activeBroadcasts = broadcastsService.getActiveBroadcasts();

      for (let i = 0; i < this.activeBroadcasts.length; i++) {
        const companyListItem: HTMLElement = document.getElementById(`companyBadge_${this.activeBroadcasts[i].details.promopage.external_id}`);

        if (companyListItem) {
          if (this.activeBroadcasts[i].type === BroadcastType.PROMO_CONTACT) {
            companyListItem.classList.add('active-broadcast');
          }
        }
      }
    }, 5000);
  }

  public async showCompany(externalId: string): Promise<void> {

    await this.$store.dispatch('promoStore/getPromoPage', {
      event_id: this.$route.params.eventId,
      external_id: externalId,
    });

    await this.$router.push({
      name: 'promo-page-events-company',
      params: {
        eventId: this.$route.params.eventId,
        external_id: externalId,
      }
    }).catch(/* ignore */);

    this.scrollOnTopByExternalId(externalId);
  }

  // public toggleFavorite(company: TPromoPage): void {
  //   const payload: { event_id: string; external_id: string } = {
  //     event_id: this.$route.params.eventId,
  //     external_id: company.external_id
  //   };
  //
  //   company.is_favorite ? this.removeFavorite(payload) : this.addFavorite(payload);
  //
  //   if (company.external_id === this.$route.params.external_id) {
  //     this.$store.dispatch('promoStore/getPromoPage', payload);
  //   }
  // }

  public scrollOnTopByExternalId(externalId?: string): void {
    const extId: string = externalId || this.$route.params.external_id || null;
    const chosen: Vue[] = this.$refs['companyBadge_' + extId] as Vue[];
    if (!extId || !chosen) {
      setTimeout(this.scrollOnTopByExternalId, 333);
      return;
    }
    (this.$refs.scrollerCompanies as HTMLElement).scrollTo({
      top: (chosen[0].$el as HTMLDivElement).offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  }

  public scrollOnTop(event: PointerEvent): boolean {
    const scroller: HTMLElement = (this.$refs.scrollerCompanies as Vue).$el as HTMLElement;
    const halfHeight = 70;
    const difference = Math.max(0, (event.target as HTMLElement).offsetTop - halfHeight) - scroller.scrollTop;
    let count = 10; // 10 results in no more than 60 timeouts
    const step = difference / 10; // pixel amount
    const stepper = (): void => {
      scroller.scrollTop += step;
      count--;
      if (count > 0) {
        window.setTimeout(stepper, 11);
      }
    };
    window.setTimeout(stepper, 11);
    return false; // Stopping from passing the event into further scroll handlers
  }

  public async onInfiniteHandler ($state?: StateChanger): Promise<void> {
    if (this.isListTypeAll) {
      await this.setListAll();
      if ($state) {
        $state.loaded();
      }
    } else if (this.isListTypeMy) {
      await this.setListMy();
      if ($state) {
        $state.loaded();
      }
    }
  }

  // AW-3130
  public onCompanyCardClosed(eventData: { externalId: string }): void {
    const { externalId } = eventData;
    if (!externalId) {
      return;
    }
    if (this.isCompaniesListVertical) {
      this.$nextTick(() => {
        this.onCompanyCardClosed(eventData);
      });
      return;
    }
    const targetListItem: Vue[] = this.$refs['companyBadge_' + externalId] as Vue[];
    if (!targetListItem) {
      this.$nextTick(() => {
        this.onCompanyCardClosed(eventData);
      });
      return;
    }
    this.$nextTick(() => {
      window.scrollTo(0, (targetListItem[0].$el as HTMLDivElement).offsetTop - 128); // lazy minus searchbar and top bar
    });
  }

}
