


















































































// @ts-nocheck
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
// @ts-ignore
import truncate from 'vue-truncate-collapsed';
import IconStar from '@/_modules/icons/components/icon-star.vue';
import IconMessage from '@/_modules/icons/components/icon-message.vue';
import broadcastsService from '@/_services/broadcasts.service';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';

const NO_AVATAR_COMPANY = require('@/assets/images/no-avatar-company-60x60.svg');
const LIVE_INDICATOR = require('@/assets/images/live-indicator.svg');

export default {
  name: 'seat-plan',
  components: { truncate, IconStar, IconMessage },
  computed: {
    ...mapState({
      planEventId: state => state.seatplanStore.eventId,
      planIsLoading: state => state.seatplanStore.isLoading,
      planJson: state => state.seatplanStore.planJson,
      promoPageListLoading: state => state.promoStore.promoPageListLoading,
      promoPageList: state => state.promoStore.promoPageList,
    }),
    ...mapGetters({
      promoPages: 'promoStore/promoPages',
    }),
    isSeatplanVisible(): boolean {
      return (
        this.planEventId === this.$route.params.eventId
        && !this.planIsLoading
        && !this.promoPageListLoading
        && !!this.planJson
      );
    },
    favoriteStarColor(): string {
      return this.currentPromoPage.is_favorite ? 'red' : 'gold';
    },
    currentPromoPageAvatarSrc(): string {
      if (!this.currentPromoPage || !this.currentPromoPage.logo_url) {
        return NO_AVATAR_COMPANY;
      } else {
        return this.currentPromoPage.logo_url;
      }
    },
    seatPlanIframeSrc(): string {
      return `/${this.$route.params.lang}/seat-plan`;
    }
  },
  watch: {
    promoPageList: {
      immediate: true,
      handler(): void {
        this.promoPageMapByExternalId.clear();
        if (!this.promoPageList || !this.promoPageList.length) {
          return;
        }
        this.promoPageList.forEach(promoPage => {
          if (promoPage.external_id) {
            this.promoPageMapByExternalId.set(promoPage.external_id, promoPage);
          }
        });
      }
    },
    activeBroadcasts: {
      handler(): void {
        if(this.activeBroadcasts.length !== this.broadcastItems.length) {
          const frameBody = document.querySelector('.seat-plan-iframe').contentWindow.document.body;
          window.companies = frameBody.querySelectorAll('.label');

          const indicators = frameBody.querySelectorAll('.icon-live-indicator');

          indicators.forEach(item => {
            item.remove();
          });
        }
      }
    }
  },
  created(): void {
    this.eventId = this.$route.params.eventId;
    document.addEventListener('click', this._onDocumentClick);
  },
  mounted(): void {
    this._getSeatplanJson();
  },
  beforeDestroy(): void {
    clearInterval(this.findBroadCastInterval);
    clearInterval(this.promoPagesInterval);

    const frame = document.querySelector('.seat-plan-iframe');
    if (frame) {
      window.companies = frame.contentWindow.document.body.querySelectorAll('.label');

      const indicators = frame.contentWindow.document.body.querySelectorAll('.icon-live-indicator');

      indicators.forEach(item => {
        item.remove();
      });
    }
  },
  destroyed(): void {
    clearInterval(this.findBroadCastInterval);
    clearInterval(this.promoPagesInterval);
    document.removeEventListener('click', this._onDocumentClick);
    if (this.$refs.setPlanIframe) {
      this.$refs.setPlanIframe.contentWindow.document.removeEventListener('click', this._onDocumentClick);
    }
    if (this.seatPlanViewer) {
      this.seatPlanViewer.removeEventListener('layerchange', this.onViewerLayerChange);
      this.seatPlanViewer.removeEventListener('shapeclick', this.onViewerShapeClick);
    }
  },
  data(): void {
    return {
      eventId: null,
      currentPromoPage: null,
      clientX: null,
      clientY: null,
      popupShowLeft: false,
      popupShowTop: false,
      promoPageMapByExternalId: new Map(),
      seatPlanViewer: null,
      broadcastItems: [],
      activeBroadcasts: [],
      findBroadCastInterval: null,
      promoPagesInterval: null,
    };
  },
  methods: {
    ...mapActions({
      setListAll: 'promoPageStore/setListAll',
      addFavorite: 'promoPageStore/addFavorite',
      removeFavorite: 'promoPageStore/removeFavorite'
    }),
    ...mapMutations({
      SET_VIEW_TYPE: 'promoPageStore/SET_VIEW_TYPE',
      SET_LIST_TYPE: 'promoPageStore/SET_LIST_TYPE'
    }),

    onShowCompanyClick(external_id: string): void {
      this.SET_VIEW_TYPE('list');
      this.SET_LIST_TYPE('all');
      this.setListAll();
      this.$router.push({
        name: 'promo-page-events-company',
        params: {
          eventId: this.$route.params.eventId,
          external_id,
        }
      }).catch(() => {
        /* ignore */
      });
    },
    toggleFavorite(): void {
      const payload = {
        event_id: this.$route.params.eventId,
        external_id: this.currentPromoPage.external_id
      };
      this.currentPromoPage.is_favorite ? this.removeFavorite(payload) : this.addFavorite(payload);
      this.currentPromoPage.is_favorite = !this.currentPromoPage.is_favorite;
    },
    onViewerShapeClick(event: CustomEvent): void { // TODO: add type for CustomEvent<T>
      this.currentPromoPage = null;
      const { detail: { shape, originalEvent } } = event;

      if (shape.TYPE !== 'booth' || !shape.label) {
        return;
      }

      const promoPage = this.promoPageMapByExternalId.get(shape.label);
      if (!promoPage) {
        return;
      }
      this.popupShowLeft = false;
      this.popupShowTop = false;

      let x = originalEvent.clientX;
      let y = originalEvent.clientY;

      if (this.$refs.setPlanIframe) {
        const boundingRect = this.$refs.setPlanIframe.getBoundingClientRect();
        if (boundingRect) {
          x += boundingRect.x;
          y += boundingRect.y;
        }
      }

      this.clientX = x;
      this.clientY = y;
      this.currentPromoPage = promoPage;
      originalEvent.stopPropagation();

      this.$nextTick(() => {
        this._checkCompanyPopupPosition();
      });
    },
    onViewerShapeMouseOut(): void {
      this.currentPromoPage = null;
    },
    onViewerLayerChange(event: CustomEvent): void { // TODO: create some type for .detail, add as T → CustomEvent<T>
      clearInterval(this.findBroadCastInterval);
      const layer = event.detail;
      const shapes = layer && layer.getShapes();
      if (!shapes || !shapes.length) {
        return;
      }
      window.seatPlanLabels = document.querySelector('.seat-plan-iframe').contentWindow.document.body.querySelectorAll('.label');
      shapes.forEach((shape) => {
        if (shape.TYPE !== 'booth') {
          return;
        }
        const label = shape.label;
        if (!label) {
          return;
        }
        const company = this.promoPageMapByExternalId.get(label);

        this.findBroadCastInterval = setInterval(() => {
          this.activeBroadcasts = broadcastsService.getActiveBroadcasts();
          for (let i = 0; i < this.activeBroadcasts.length; i++) {
            if (this.activeBroadcasts[i].details.promopage.external_id === (company && company.external_id)) {
              if (this.broadcastItems.indexOf(company.external_id) < 0) {
                this.broadcastItems.push(company.external_id);
              }

              const frame = document.querySelector('.seat-plan-iframe');
              if (frame) {
                window.companies = frame.contentWindow.document.body.querySelectorAll('.label');

                this._addActiveBroadcastIndicator();
              }
            }
          }
        }, 10000);

        if (!company) {
          return;
        }

        if (company.logo_url) {
          shape.backgroundImage = company.logo_url;
        } else {
          shape.title = company.title;
        }
      });
    },
    onSeatPlanIframeLoad(): void {
      if (!this.$refs.setPlanIframe) {
        return;
      }
      const seatPlanContainer = this.$refs.setPlanIframe.contentWindow.document.getElementById('seat-plan-container');
      if (!seatPlanContainer) {
        return;
      }
      const seatPlanViewer = seatPlanContainer.firstChild;
      if (!seatPlanViewer) {
        return;
      }

      this.seatPlanViewer = seatPlanViewer;
      this.$refs.setPlanIframe.contentWindow.document.addEventListener('click', this._onDocumentClick);
      this.seatPlanViewer.addEventListener('layerchange', this.onViewerLayerChange);
      this.seatPlanViewer.addEventListener('shapeclick', this.onViewerShapeClick);
      this.seatPlanViewer.setAttribute('seatplan', this.planJson);
    },
    _getSeatplanJson(): void {
      this.$store.dispatch('seatplanStore/getSeatplanJson', {
        eventId: this.eventId,
      });
    },
    _onDocumentClick(event: any): void {
      const mainContainer = document.querySelector('.event-map');
      const selector = '.companies-wrapper';
      const closest = event.target.closest(selector);
      if (closest && mainContainer.contains(closest)) {
        return;
      }
      this.currentPromoPage = null;
    },
    _checkCompanyPopupPosition(): void {
      if (!this.$refs.companySeatplanPopup) {
        return;
      }
      const popupClientRect = this.$refs.companySeatplanPopup.getBoundingClientRect();
      if (!popupClientRect) {
        return;
      }

      if (document.documentElement.clientWidth <= this.clientX + popupClientRect.width) {
        this.popupShowLeft = true;
      }
      if (document.documentElement.clientHeight <= this.clientY + popupClientRect.height) {
        this.popupShowTop = true;
      }
    },
    _addActiveBroadcastIndicator(): void {
      if (!window.seatPlanLabels) {
        return;
      }
      for (let i = 0; i < window.seatPlanLabels.length; i++) {
        if (!window.companies) { break; }

        for (let j = 0; j < this.broadcastItems.length; j++) {
          if (window.companies[i].firstElementChild.innerHTML === this.broadcastItems[j]) {
            if (!window.companies[i].classList.contains('live-broadcast')) {

              window.companies[i].classList.add('live-broadcast');
              const elemNS = document.createElementNS('http://www.w3.org/2000/svg', 'image');

              elemNS.setAttributeNS(null, 'height', '1350');
              elemNS.setAttributeNS(null, 'width', '3750');
              elemNS.setAttributeNS(null, 'class', 'icon-live-indicator');
              elemNS.setAttributeNS('http://www.w3.org/1999/xlink', 'href', LIVE_INDICATOR);
              elemNS.setAttribute('x', '10');
              elemNS.setAttribute('y', '10');
              elemNS.innerHTML = ' <animate />';
              window.companies[i].parentNode.insertBefore(elemNS, window.companies[i].children[3]);
            }
          }
        }
      }
    },
    linkifyUrls(inputString: string): string {
      return ChatHelper.createLinks(inputString);
    },
  }
};
